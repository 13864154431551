import React, { useMemo } from 'react'

export const QueryOptsContext = React.createContext({})

export function QueryOptsProvider({ children, handle, env }) {
  const baseUrl = buildBaseUrl(handle, env)

  const value = useMemo(() => ({ baseUrl }), [baseUrl])

  return (
    <QueryOptsContext.Provider value={value}>
      {children}
    </QueryOptsContext.Provider>
  )
}

export function useQueryOpts() {
  return React.useContext(QueryOptsContext)
}

function buildBaseUrl(handle, env) {
  if (env === 'development') return `http://${handle}.localhost:3000`
  if (env === 'staging') return `https://${handle}.staging-fullfabric.cloud`
  return `https://${handle}.fullfabric.cloud`
}
